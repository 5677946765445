import { Injectable } from "@angular/core";

import { UnsavedCheckPage } from "../interfaces/unsaved-check-page.interface";

@Injectable()
export class CanDeactivateUnsavedCheck  {

    canDeactivate(component: UnsavedCheckPage): Promise<boolean> | boolean {
        return component.canLeavePage();
    }
}